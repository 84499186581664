<template>
    <div class="page-test-icons">
        <i class="test-icon-customers"></i>
        <i class="test-icon-aerial"></i>
        <i class="test-icon-globe"></i>
        <i class="test-icon-wifi"></i>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.page-test-icons {
    display: flex;

    [class^='test-icon-'] {
        width: 24px;
        height: 24px;

        margin: 12px;

        color: #333;
    }

    .test-icon-customers {
        @include icon-before($icon-nav-customers);
    }

    .test-icon-aerial {
        @include icon-before($icon-nav-fwa);
    }

    .test-icon-globe {
        @include icon-before($icon-nav-fibre);
    }

    .test-icon-wifi {
        @include icon-before($icon-nav-mobile);
    }
}
</style>